import React, { useRef, useState, useCallback, useEffect } from "react";
import "../chat.scss";
import ChatHeader from "../ChatHeader";
import ChatMessage from "../ChatMassage";
import TypingIndicator from "../TypingIndicator";
import ChatInput from "../ChatInput";
import { ChatService } from "../../services/chat-service";
import { error_responses } from "../../constants/error-responses";
import Footer from "../footer/footer-1";
import Buttons from "../button";
import LoginLogo from "../../assets/images/Logo_Dark.png";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from "react-router";
import { removeData } from "../../utills/local-storage";
import { STORAGE_KEYS } from "../../utills/enums";
import { TabSelector } from "../tabView";
import { TabPanel, useTabs } from "react-headless-tabs";
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from "react-tooltip";
import ButtonStatus from "../../components/button/button-one";
import { DocumentService } from "../../services/document-service";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useErrorBoundary } from "react-error-boundary";
import { CustomerService } from "../../services/customer-service";
import moment from "moment";
import ProcessIcon from "../../assets/images/process.png";
import TrashIcon from "../../assets/images/trash.png";
import { motion, useCycle } from "framer-motion";
import HamBurgerMenu from "../hamburger/index";
import { useSwitch } from "../../context/FullScreenProvider";

type Message = {
	text: string;
	isUser: boolean;
	historyItem?: boolean;
};

type ChatHistoryItem = {
	question: string;
	answer: string;
};

type Document = {
	id: number;
	name: string;
	status: number;
	createdAt: Date;
};

type WebUrl = {
	id: number;
	url: string;
	status: number;
	createdAt: Date;
};

type WebTabStates = "idle" | "unsaved" | "saved";
const fileTypes = ["pdf", "doc", "docx"];

const LayoutPreview = () => {
	const { isSwitchOn, toggleSwitch } = useSwitch();
	const { showBoundary } = useErrorBoundary();
	const { user, signOut } = useAuth();
	const navigate = useNavigate();
	const [messages, setMessages] = useState<Message[]>([
		{
			text: "Hi, How can I help you today?",
			isUser: false,
		},
	]);
	const [isLoading, setIsLoading] = useState(false);
	const [sessionId, setSessionId] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
	const bottomRef = useRef<HTMLDivElement | null>(null);
	const [typeStatus, setTypeStatus] = useState<number | null>(null);
	const [IsTypeCompleted, setIsTypeCompleted] = useState(true);
	const [scrollState, setScrollState] = useState(false);
	const [hasChatHistory, setHasChatHistory] = useState(false);
	const [isInitiativeBuble, setIsInitiativeBuble] = useState(true);
	const [selectedTab, setSelectedTab] = useTabs(["files", "web"]);
	const [loading, setLoading] = useState(false);
	const [documentList, setDocumentList] = useState<Document[]>([]);
	const [step, setStep] = useState<WebTabStates>("idle");
	const [baseUrl, setBaseUrl] = useState<string>("");
	const [subUrls, setSubUrls] = useState<string[]>([]);
	const [uploadPercentage, setuploadPercentage] = useState(0);
	const [isProcessLoading, setIsProcessLoading] = useState({
		state: false,
		index: -1,
	});
	const [isDeleteLoading, setIsDeleteLoading] = useState({
		state: false,
		index: -1,
	});
	const [websiteList, setWebsiteList] = useState<WebUrl[]>([]);
	const {
		register: registerFile,
		handleSubmit: handleSubmitFile,
		reset: resetFile,
	} = useForm();

	useEffect(() => {
		bottomRef?.current?.scrollIntoView();
	}, [typeStatus, scrollState]);

	const targetRef = useRef(null);

	useEffect(() => {
		if (isOpen) {
			bottomRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [isOpen]);

	useEffect(() => {
		const effect = async () => {
			const response = await CustomerService.getLoggedInCustomer();

			setWebsiteList(response.websites);

			if (response.websites.length !== 0) {
				setStep("saved");
				const url = new URL(response.websites[0].url);
				setBaseUrl(`${url.protocol}//${url.host}`);
			}
		};

		effect();
		getDocuments();
	}, []);

	const handleToggleChat = () => {
		if (!Array.isArray(chatHistory) || chatHistory.length > 0) {
			setHasChatHistory(true);
		}
		setIsOpen(() => {
			if (!isOpen && messages.length === 0) {
				setIsLoading(true);
				setTimeout(() => {
					setMessages([
						...messages,
						{
							text: "Hi, I’m IdeaBot! I have been custom-built using the latest AI intelligence to understand data from a variety of sources to generate intelligent and accurate responses. Ask me anything about IdeaBits or select an option below to get started.",
							isUser: false,
						},
					]);
					setIsLoading(false);
				}, 1000);
			}
			return !isOpen;
		});

		// set historyItem true when chat has history
		messages.map((item) => {
			item.historyItem = true;
			return item;
		});
	};

	const updateValue = (status: boolean) => {
		if (status) {
			setIsTypeCompleted(status);
		}
	};

	const renderCount = (count: number) => {
		setTypeStatus(count);
	};

	const handleSendMessageClick = async (question: string) => {
		setIsInitiativeBuble(false);
		setScrollState(true);
		setHasChatHistory(false);
		setIsTypeCompleted(false);
		setMessages((prev) => [...prev, { text: question, isUser: true }]);

		if (question.length > 200) {
			setMessages((prev) => [
				...prev,
				{
					text: error_responses.INVALID_CHARACTER_LENGTH,
					isUser: false,
				},
			]);
		} else {
			try {
				setIsLoading(true);

				const questionData = {
					question: question,
					chat_history: chatHistory,
				};

				if (messages.length > 10) {
					const firstTwoQA = chatHistory.slice(1, 3);
					const lastSixQA = chatHistory.slice(
						chatHistory.length - 4,
						chatHistory.length,
					);

					questionData.chat_history = firstTwoQA.concat(lastSixQA);
				}

				const response = await ChatService.GetChatBotResponse(
					questionData,
					sessionId,
				);
				setIsLoading(false);
				setMessages((prev) => [
					...prev,
					{ text: response.data.text, isUser: false },
				]);

				setChatHistory((prevArray) => [
					...prevArray,
					{ question: question, answer: response.data.text },
				]);

				if (response.data.session_id) {
					setSessionId(response.data.session_id);
				}
			} catch (error) {
				setMessages((prev) => [
					...prev,
					{
						text: error_responses.INTERNAL_SEVER_ERROR,
						isUser: false,
					},
				]);
				setIsLoading(false);
			}
		}
		setScrollState(false);
	};

	const bubbleOneClick = (text: string) => {
		if (!isLoading && IsTypeCompleted) {
			handleSendMessageClick(text);
			setIsInitiativeBuble(false);
		}
	};
	const getDocuments = async () => {
		try {
			const documentResponse = await DocumentService.GetDocuments();

			setDocumentList(documentResponse.documents);
		} catch (error: any) {
			showBoundary(error);
			toast.error(error.message, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};

	const handleChange = async (file: any) => {
		await onSubmitFiles(file);
	};

	const onSubmitFiles = async (data: any) => {
		setLoading(true);
		const formData = new FormData();
		formData.append("file", data);
		const fileName = data.name;

		const fileNames = {
			documentNames: [fileName],
		};

		const ssss = {
			id: -1,
			name: fileName,
			status: 3,
			createdAt: new Date(),
		};
		const result = documentList.find(
			(item) => item.name.toLowerCase() === fileName.toLowerCase(),
		);
		if (!result) {
			documentList.push(ssss);
		}

		try {
			const response = await DocumentService.GetDocumentsSignUrl(
				fileNames,
			);
			await fetch(response[0].url, {
				method: "PUT",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				body: data,
			}).then(async (res) => {
				if (res.status === 200) {
					try {
						const documentUpdate =
							await DocumentService.DocumentUpdateBackend(
								fileNames,
							);
						if (
							documentUpdate.length &&
							documentUpdate.length > 0
						) {
							toast.success("Document uploaded successfully!", {
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
							});
							resetFile();
							setLoading(false);
							getDocuments();
							setuploadPercentage(100);
						}
					} catch (error: any) {
						toast.error((error as any).response?.data.message, {
							position: "top-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
					}
				}
			});
		} catch (error2: any) {
			toast.error((error2 as any).response?.data.message, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			resetFile();
			setLoading(false);
			getDocuments();
			// getWebsite();
		}
	};

	const DeleteFile = async (name: string, index: number, id: number) => {
		setIsDeleteLoading({
			state: true,
			index: index,
		});
		const fileNames = {
			documentIds: [id],
		};

		try {
			const response = await DocumentService.DeleteDocument(fileNames);
			setIsDeleteLoading({
				state: false,
				index: -1,
			});
			getDocuments();
		} catch (error: any) {
			setIsDeleteLoading({
				state: false,
				index: -1,
			});
			toast.error((error as any).response?.statusText, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			if (error.response.data.statusCode === 401) {
				handleLogout();
			}
		}
	};

	const DocumentProcess = async (name: string, index: number, id: number) => {
		setIsProcessLoading({
			state: true,
			index: index,
		});
		const fileNames = {
			documentIds: [id],
		};

		try {
			const responce = await DocumentService.ProcessDocument(fileNames);
			getDocuments();
			setIsProcessLoading({ state: false, index: -1 });
			if (!responce[0].isSuccess) {
				toast.error("Document Processing Error", {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		} catch (error: any) {
			setIsProcessLoading({ state: false, index: -1 });
			toast.error((error as any).response?.statusText, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};

	const handleLogout = async () => {
		try {
			await signOut();
			navigate("/signin");
			await removeData(STORAGE_KEYS.USER_TOKEN);
		} catch (error) {}
	};

	const removeUrl = (toBeRemovedSubUrl: string) => {
		setSubUrls((subUrls) =>
			subUrls.filter((subUrl) => subUrl !== toBeRemovedSubUrl),
		);
		if (subUrls.length === 1) {
			setStep("idle");
		}
	};

	const onCrawl = useCallback(async (baseUrl: string) => {
		setBaseUrl(baseUrl);
		const newSubUrls = await DocumentService.getSubUrls(baseUrl);

		setSubUrls(newSubUrls);

		setStep("unsaved");
	}, []);

	const onProcess = useCallback(async () => {
		try {
			await DocumentService.ProcessWebUrl({
				urls: subUrls,
			});

			const response = await CustomerService.getLoggedInCustomer();

			setWebsiteList(response.websites);

			setStep("saved");
		} catch (error: any) {
			setStep("idle");
			toast.error((error as any).response?.data?.message, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}, [subUrls]);

	const onProcessDelete = useCallback(async (url: string) => {
		await DocumentService.ProcessFailedWebUrl({ urls: [url] });

		const response = await CustomerService.getLoggedInCustomer();

		setWebsiteList(response.websites);

		setStep("saved");
	}, []);

	const deleteWebUrl = useCallback(
		async (url: string, id: number) => {
			await DocumentService.deleteUrls([id]);

			const newWebsiteList = websiteList.filter(
				(website) => website.url !== url,
			);
			setWebsiteList(newWebsiteList);

			if (newWebsiteList.length === 0) {
				setStep("idle");
			}
		},
		[websiteList],
	);

	const fullScreenToggle = () => {
		toggleSwitch();
	};

	return (
		<div className="flex flex-col xxxxs:h-full sm:h-screen main-preview">
			<div
				className={`${
					isSwitchOn ? "hidden" : "block"
				} flex bg-pink-300 flex-row items-center preview-header justify-center shadow`}
			>
				<div className="flex flex-1 logo">
					<img src={LoginLogo} alt="logo" />
				</div>
				<div className="user-details flex flex-1 flex-row justify-end items-center">
					<p>Username : {user?.email}</p>
					<Buttons
						ButtonText="Contact us"
						onClick={() => {
							window.open(
								"https://ai.ideabits.se/contacts.html",
								"_blank",
								"noreferrer",
							);
						}}
					/>{" "}
					<Buttons ButtonText="Log Out" onClick={handleLogout} />{" "}
				</div>
				<HamBurgerMenu
					onClickContact={() => {
						window.open(
							"https://ai.ideabits.se/contacts.html",
							"_blank",
							"noreferrer",
						);
					}}
					email={user?.email}
					onClickLogout={handleLogout}
				/>
			</div>

			<div
				className={`flex flex-1 flex-col md:flex-row overflow-y-auto overflow-x-hidden preview-controller `}
			>
				<div
					className={`flex  overflow-y-auto paragraph overflow-x-hidden preview-content ${
						isSwitchOn ? "hidden md:w-1" : "block md:w-1/2"
					} `}
				>
					<div className="h-full">
						<div className="px-8 py-8">
							<div className="max-w-4xl flex flex-col items-start">
								<nav className="relative">
									<TabSelector
										isActive={selectedTab === "files"}
										onClick={() => setSelectedTab("files")}
									>
										Files
									</TabSelector>
									<TabSelector
										isActive={selectedTab === "web"}
										onClick={() => setSelectedTab("web")}
									>
										Web
									</TabSelector>
								</nav>
								<div className="relative">
									<TabPanel hidden={selectedTab !== "files"}>
										<div className="file-upload flex flex-col items-start justify-start py-8">
											<form
												key={1}
												className="flex flex-col drag-drop-wrapper"
											>
												<label>Upload</label>
												<div className="flex flex-row items-center justify-center"></div>
												<FileUploader
													handleChange={handleChange}
													name="file"
													types={fileTypes}
													maxSize={50}
													multiple={false}
													children={
														<div className="drag-drop-container">
															<div className="drag-drop flex flex-row justify-end items-center border-dashed border-1">
																<img
																	src={require("../../assets/images/drag-and-drop.png")}
																	alt="drag-drop"
																/>
																<p>
																	Drag and
																	Drop here or
																	Choose a
																	file
																</p>
															</div>
														</div>
													}
												/>
											</form>
										</div>

										<div className="file-upload-table ">
											<p className="table-header">
												Existing Sources
											</p>
											<div className="inline-block min-w-full shadow rounded-lg overflow-x-auto table-wrapper">
												<table className="min-w-full leading-normal bg-white ">
													<thead>
														<tr>
															<th className="px-5 py-3 text-left  tracking-wider">
																File Name
															</th>
															<th className="px-5 py-3 bg-gray-100 text-center tracking-wider">
																Date
															</th>
															<th className="px-5 py-3 text-center  tracking-wider">
																Status
															</th>
															<th className="px-5 py-3 text-center tracking-wider">
																Actions
															</th>
														</tr>
													</thead>
													<tbody>
														{documentList.length &&
														documentList.length > 0
															? documentList.map(
																	(
																		item,
																		index,
																	) => (
																		<tr>
																			<td className="px-5 py-5  bg-white text-sm">
																				<div className="flex items-center source-name">
																					<span
																						data-tooltip-id={`${item.id}`}
																						data-tooltip-content={
																							item.name
																						}
																					>
																						{
																							item.name
																						}
																					</span>
																					<Tooltip
																						id={`${item.id}`}
																						place="top-start"
																						noArrow={
																							true
																						}
																					/>
																				</div>
																			</td>
																			<td className="px-5 py-5 bg-white text-sm">
																				<p className="text-gray-900 whitespace-no-wrap">
																					{moment(
																						item.createdAt,
																					).format(
																						"L",
																					)}
																				</p>
																			</td>
																			<td className="px-5 py-5 bg-white text-sm">
																				<ButtonStatus
																					type="document"
																					ButtonText="Save"
																					ButtonType="normal"
																					status={
																						item.status
																					}
																				/>
																			</td>
																			<td className="px-5 py-5  bg-white flex flex-row">
																				<ButtonStatus
																					ButtonText="Process"
																					ButtonType="icon"
																					icon={
																						ProcessIcon
																					}
																					disable={
																						item.status !==
																						0
																					}
																					onClick={() =>
																						DocumentProcess(
																							item.name,
																							index,
																							item.id,
																						)
																					}
																					loading={
																						isProcessLoading.index ===
																						index
																					}
																				/>
																				<ButtonStatus
																					ButtonText="Delete"
																					ButtonType="icon"
																					icon={
																						TrashIcon
																					}
																					// disable={true}
																					onClick={() =>
																						DeleteFile(
																							item.name,
																							index,
																							item.id,
																						)
																					}
																					loading={
																						isDeleteLoading.index ===
																						index
																					}
																				/>
																			</td>
																		</tr>
																	),
															  )
															: null}
													</tbody>
												</table>
												{documentList.length === 0 && (
													<div className="no-data-found">
														<p>
															No Documents Found
														</p>
													</div>
												)}
											</div>
										</div>
									</TabPanel>
									<TabPanel hidden={selectedTab !== "web"}>
										{step === "idle" && (
											<MainUrlSection
												status={step}
												defaultUrl=""
												onCrawl={onCrawl}
											/>
										)}
										{step === "unsaved" && (
											<MainUrlSection
												status={step}
												defaultUrl={baseUrl}
												onProcess={onProcess}
											/>
										)}
										{step === "saved" && (
											<MainUrlSection
												status={step}
												defaultUrl={baseUrl}
											/>
										)}
										{step === "unsaved" && (
											<SubUrlTable
												urls={subUrls}
												onDelete={removeUrl}
											/>
										)}
										{step === "saved" && (
											<WebsiteTable
												webUrls={websiteList}
												onDelete={deleteWebUrl}
												onProcess={onProcessDelete}
											/>
										)}
									</TabPanel>
								</div>
								<div className="xxxxs:block md:hidden preview-chat-mobile">
									<div
										className={`ideabot-chat-widget mobile`}
									>
										<ChatHeader
											heading={"Ideabot"}
											handleToggle={fullScreenToggle}
										/>

										<div className="ideabot-chat-messages">
											{messages.map((message, index) => (
												<ChatMessage
													index={index}
													isUser={message.isUser}
													text={message.text}
													newMessage={message}
													finishStatus={updateValue}
													renderCount={renderCount}
													hasChatHistory={
														hasChatHistory
													}
													isFirstMessage={
														isInitiativeBuble
													}
													bubbleOneClick={
														bubbleOneClick
													}
												/>
											))}

											{isLoading && <TypingIndicator />}
											<div ref={bottomRef} />
										</div>
										<ChatInput
											handleSendMessageClick={
												handleSendMessageClick
											}
											isDisabled={!IsTypeCompleted}
										/>
										<Footer />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<aside
					className={`xxxxs:hidden md:block md:w-1/2 overflow-y-auto preview-chat ${
						isSwitchOn ? "!w-full" : " md:w-1/2"
					}`}
					ref={targetRef}
				>
					<div className={`ideabot-chat-widget`}>
						<ChatHeader
							heading={"Ideabot"}
							handleToggle={fullScreenToggle}
						/>
						<div className="ideabot-chat-messages">
							{messages.map((message, index) => (
								<ChatMessage
									index={index}
									isUser={message.isUser}
									text={message.text}
									newMessage={message}
									finishStatus={updateValue}
									renderCount={renderCount}
									hasChatHistory={hasChatHistory}
									isFirstMessage={isInitiativeBuble}
									bubbleOneClick={bubbleOneClick}
								/>
							))}

							{isLoading && <TypingIndicator />}
							<div ref={bottomRef} />
						</div>
						<ChatInput
							handleSendMessageClick={handleSendMessageClick}
							isDisabled={!IsTypeCompleted}
						/>
						<Footer />
					</div>
				</aside>
			</div>
		</div>
	);
};

type UnprocessedSubUrlProps = {
	url: string;
	onDelete: (url: string) => void;
};

type ProcessedSubUrlProps = {
	url: string;
	status: string;
	createdAt: string;
	onDelete: () => Promise<void>;
};

type MainUrlSectionProps = {
	defaultUrl: string;
} & (
	| {
			status: "unsaved";
			onProcess: () => Promise<void>;
	  }
	| {
			status: "saved";
	  }
	| {
			status: "idle";
			onCrawl: (url: string) => Promise<void>;
	  }
);

const MainUrlSection = (props: MainUrlSectionProps) => {
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, setValue, formState, getValues } = useForm({
		defaultValues: {
			mainUrl: props.defaultUrl,
		},
	});
	if (props.status === "saved") {
		return (
			<div className="web-url-container py-8">
				<label>Website URL</label>
				<div className="input-wrapper flex flex-row">
					<label>{props.defaultUrl}</label>
				</div>
			</div>
		);
	}

	if (props.status === "idle") {
		const { onCrawl } = props;

		const onCrawlClick = async (state: { mainUrl: string }) => {
			setLoading(true);
			await onCrawl(state.mainUrl);
			setLoading(false);
		};

		return (
			<div className="web-url-container py-8">
				<form onSubmit={handleSubmit(onCrawlClick)}>
					<label>Website URL</label>
					<p>
						Include your website URL here to extract data. This URL
						will crawl all the data of your website.
					</p>
					<div className="input-wrapper flex flex-row">
						<input
							{...register("mainUrl", {
								required: true,
							})}
							type="text"
							placeholder="www.test.com"
						/>
						<Buttons
							loading={loading}
							ButtonText="Crawl"
							ButtonType="auth-form"
						/>
					</div>
				</form>
			</div>
		);
	}

	const { onProcess } = props;

	const onProcessClick = async (state: { mainUrl: string }) => {
		setLoading(true);
		await onProcess();
		setLoading(false);
	};

	return (
		<div className="web-url-container py-8">
			<form onSubmit={handleSubmit(onProcessClick)}>
				<label>Website URL</label>
				<p>
					Include your website URL here to extract data. This URL will
					crawl all the data of your website.
				</p>
				<div className="input-wrapper flex flex-row">
					<input
						{...register("mainUrl", {
							required: true,
							disabled: true,
						})}
						type="text"
					/>
					<button
						onClick={() => {
							setValue("mainUrl", "");
						}}
					></button>
					<Buttons
						ButtonText="Process"
						loading={loading}
						ButtonType="auth-form"
					/>
				</div>
			</form>
		</div>
	);
};

type WebsiteRowProps = {
	id: number;
	url: string;
	status: number;
	createdAt: Date;
	onDelete: (url: string, id: number) => Promise<void>;
	onProcess: (url: string) => Promise<void>;
};

const WebsiteRow = ({
	id,
	url,
	status,
	createdAt,
	onDelete,
	onProcess,
}: WebsiteRowProps) => {
	const [isLoading, setLoading] = useState(false);
	const [isLoadingDelete, setLoadingDelete] = useState(false);
	const onDeleteClick = async () => {
		setLoadingDelete(true);

		await onDelete(url, id);

		setLoadingDelete(false);
	};

	const onProcessClick = async () => {
		setLoading(true);

		await onProcess(url);

		setLoading(false);
	};

	return (
		<tr>
			<td className="px-5 py-5  bg-white text-sm">
				<div className="flex items-center source-name">
					<span data-tooltip-id={`${id}`} data-tooltip-content={url}>
						{url}
					</span>
					<Tooltip id={`${id}`} place="top-start" noArrow={true} />
				</div>
			</td>
			<td className="px-5 py-5 bg-white text-sm">
				<p className="text-gray-900 whitespace-no-wrap">
					{moment(createdAt).format("L")}
				</p>
			</td>
			<td className="px-5 py-5 bg-white text-sm">
				<ButtonStatus
					type="website"
					ButtonText="Save"
					ButtonType="normal"
					status={status}
				/>
			</td>
			<td className="px-5 py-5  bg-white flex flex-row">
				<ButtonStatus
					ButtonText="Process"
					ButtonType="icon"
					icon={ProcessIcon}
					status={status}
					disable={status === 0}
					loading={isLoading}
					onClick={onProcessClick}
				/>
				<ButtonStatus
					ButtonText="Delete"
					ButtonType="icon"
					icon={TrashIcon}
					status={status}
					loading={isLoadingDelete}
					onClick={onDeleteClick}
					disable={status === 1}
				/>
			</td>
		</tr>
	);
};

type SubUrlProps = {
	url: string;
	onDelete: (url: string) => void;
	id: number;
};

const SubUrlRow = ({ url, onDelete, id }: SubUrlProps) => {
	return (
		<tr>
			<td className="px-5 py-5  bg-white text-sm">
				<div className="flex items-start source-name-url">
					<span data-tooltip-id={`${id}`} data-tooltip-content={url}>
						{url}
					</span>
					<Tooltip id={`${id}`} place="top-start" noArrow={true} />
				</div>
			</td>
			<td className="px-5 py-5  bg-white flex flex-row justify-center">
				<ButtonStatus
					ButtonText="Delete"
					ButtonType="icon"
					icon={TrashIcon}
					status={0}
					onClick={() => onDelete(url)}
				/>
			</td>
		</tr>
	);
};

type SubUrlTableProps = {
	urls: string[];
	onDelete: (url: string) => void;
};

const SubUrlTable = ({ urls, onDelete }: SubUrlTableProps) => {
	return (
		<div className="file-upload-table">
			<p className="table-header">Sub URLS</p>
			<div className="inline-block min-w-full shadow rounded-lg overflow-x-auto suburl-table-container">
				<table className="min-w-full leading-normal bg-white overflow-x-auto">
					<thead>
						<tr>
							<th className="px-5 py-3 text-left  tracking-wider">
								URL
							</th>
							<th className="px-5 py-3 text-center tracking-wider">
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{urls.map((url, index) => (
							<SubUrlRow
								url={url}
								id={index}
								onDelete={onDelete}
							/>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

type WebsiteTableProps = {
	webUrls: WebUrl[];
	onDelete: (url: string, id: number) => Promise<void>;
	onProcess: (url: string) => Promise<void>;
};

const WebsiteTable = ({ webUrls, onDelete, onProcess }: WebsiteTableProps) => {
	return (
		<div className="file-upload-table">
			<p className="table-header">Sub URLS</p>
			<div className="inline-block min-w-full shadow rounded-lg overflow-x-auto suburl-table-container">
				<table className="min-w-full leading-normal bg-white">
					<thead>
						<tr>
							<th className="px-5 py-3  text-left tracking-wider">
								URL
							</th>
							<th className="px-5 py-3  text-center tracking-wider">
								Date
							</th>
							<th className="px-5 py-3 text-center  tracking-wider">
								Status
							</th>
							<th className="px-5 py-3 text-center tracking-wider">
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{webUrls.map((webUrl) => (
							<WebsiteRow
								{...webUrl}
								onDelete={onDelete}
								onProcess={onProcess}
							/>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default LayoutPreview;
