import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import ErrorPage from "./components/errorPage";
import { SwitchProvider } from "./context/FullScreenProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

type FallbackRenderProps = {
	error: any;
	resetErrorBoundary: any;
};
const FallbackRender: React.FC<FallbackRenderProps> = ({
	error,
	resetErrorBoundary,
}) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.
	const { resetBoundary } = useErrorBoundary();
	return <ErrorPage resetBoundary={resetBoundary} />;
};
root.render(
	<React.StrictMode>
		<SwitchProvider>
			<ErrorBoundary
				fallback={
					<FallbackRender
						error={undefined}
						resetErrorBoundary={undefined}
					/>
				}
			>
				<BrowserRouter>
					<App />
					<ToastContainer />
				</BrowserRouter>
			</ErrorBoundary>
		</SwitchProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
