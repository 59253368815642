import React, { useState } from "react";
import LoginBackgroundImage from "../../assets/images/pl1.png";
import LoginLogo from "../../assets/images/logo-color.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { createClient } from "@supabase/supabase-js";
import Buttons from "../../components/button";
import { useNavigate } from "react-router-dom";

type Inputs = {
	email: string;
	password: string;
	keepRemember: boolean;
};

const supabasePrivateKey = process.env.REACT_APP_SUPABASE_PRIVATE_KEY ?? "";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ?? "";
const supabase = createClient(supabaseUrl, supabasePrivateKey);

const ForgotPassowrd = () => {
	const [error, setError] = useState<string | null>(null);
	const [successfull, setSuccessfull] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const onSubmit: SubmitHandler<Inputs> = async (credential) => {
		setSuccessfull(null);
		setLoading(true);
		const dataObject = {
			email: credential.email,
		};
		const { data } = await supabase.auth.resetPasswordForEmail(
			dataObject.email,
			{
				redirectTo: `${process.env.REACT_APP_WEB_URL}/resetPassword`,
			},
		);
		if (data) {
			setLoading(false);
			setSuccessfull(
				"Email already sent to your mailbox. Please confirm it!.",
			);
		}
	};

	return (
		<div className="login-page">
			<div className="flex flex-row">
				<div className="login-left flex-1 min-h-screen xxxxs:hidden lg:block">
					<img
						src={LoginBackgroundImage}
						className="w-full h-full"
						alt="login-background"
					/>
				</div>
				<div className="login-right flex flex-1 flex-col justify-center items-center sm:h-screen xs:h-screen xxs:h-screen xxxs:h-screen xxxxs:h-screen">
					<div className="logo">
						<img src={LoginLogo} className="w-40" alt="login" />
					</div>
					<div className="login-form">
						<p className="form-title">Find your account</p>

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-data">
								<label>Email ID</label>
								<input
									placeholder="yourname@company.com"
									{...register("email", {
										required: true,
										validate: {
											maxLength: (v) =>
												v.length <= 50 ||
												"The email should have at most 50 characters",
											matchPattern: (v) =>
												/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
													v,
												) ||
												"Email address must be a valid address",
										},
									})}
									onChange={() => {
										setSuccessfull(null);
										setError(null);
									}}
								/>
							</div>

							<div className="submit-container">
								<Buttons
									ButtonText="Send recovery link"
									loading={loading}
									ButtonType="auth-form"
								/>
							</div>
							<p className="sucussfull-hanlde">
								{successfull && successfull}
							</p>
							<p className="error-handler">
								{error ? error : ""}
								<br />
								{errors.email && errors.email.message}
								<br />
							</p>

							<div className="login-bottom">
								<p>
									Do you remember password?{" "}
									<a
										href="#"
										onClick={() => {
											navigate("/signin");
										}}
									>
										Sign In
									</a>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassowrd;
