import React, { useState } from "react";
import LoginBackgroundImage from "../../assets/images/pl1.png";
import LoginLogo from "../../assets/images/logo-color.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { createClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/button";

type Inputs = {
	email: string;
	password: string;
	password_repeat: string;
};

const supabasePrivateKey = process.env.REACT_APP_SUPABASE_PRIVATE_KEY ?? "";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ?? "";
const supabase = createClient(supabaseUrl, supabasePrivateKey);

const ResetPassword = () => {
	const [error, setError] = useState<string | null>(null);
	const [successfull, setSuccessfull] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const search = window.location.search;
	const params = new URLSearchParams(search);

	const foo = params.get("token");
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<Inputs>();
	const password = watch("password", "");
	const onSubmit: SubmitHandler<Inputs> = async (credential) => {
		setError(null);
		setLoading(true);
		const dataObject = {
			password: credential.password,
		};
		const { data, error } = await supabase.auth.updateUser(dataObject);
		if (error?.status) {
			setError(error.message);
			setLoading(false);
		}

		if (
			data.user?.identities?.length &&
			data.user?.identities?.length > 0
		) {
			setSuccessfull("You have succesfully reset your passowrd!");
			setLoading(false);
			await supabase.auth.signOut();
			setTimeout(() => {
				navigate("/signin");
			}, 2000);
		}
	};

	return (
		<div className="login-page">
			<div className="flex flex-row">
				<div className="login-left flex-1 min-h-screen xxxxs:hidden lg:block">
					<img
						src={LoginBackgroundImage}
						className="w-full h-full"
						alt="login-background"
					/>
				</div>
				<div className="login-right flex flex-1 flex-col justify-center items-center sm:h-screen xs:h-screen xxs:h-screen xxxs:h-screen xxxxs:h-screen">
					<div className="logo">
						<img src={LoginLogo} className="w-40" alt="login" />
					</div>
					<div className="login-form">
						<p className="form-title">Enter your New password</p>

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-data">
								<label>Password</label>
								<input
									type="password"
									{...register("password", {
										required: "You must specify a password",
										minLength: {
											value: 6,
											message:
												"Password must have at least 6 characters",
										},
									})}
								/>

								<label>Repeat password</label>
								<input
									type="password"
									{...register("password_repeat", {
										validate: (value) =>
											value === password ||
											"The passwords do not match",
									})}
								/>
							</div>

							<div className="submit-container">
								<Buttons
									ButtonText="Update your passowrd"
									loading={loading}
									ButtonType="auth-form"
								/>
							</div>
							<p className="error-handler">
								{error ? error : ""}
								<br />
								{errors.password && errors.password.message}
								<br />
								{errors.password_repeat &&
									errors.password_repeat.message}
							</p>
							<p className="sucussfull-hanlde">
								{successfull && successfull}
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
