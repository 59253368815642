import "./styles.scss";
import closeBtn from "../../images/Close.png";
import resizeIcon from "../../assets/images/resize.png";
import avatar_a from "../../images/Ideabot-avatar_new.png";
import { useSwitch } from "../../context/FullScreenProvider";

type Props = {
	heading?: string;
	styles?: React.CSSProperties;
	handleToggle: React.MouseEventHandler;
};

const ChatHeader = (props: Props) => {
	const { isSwitchOn } = useSwitch();
	return (
		<div className="ideabot-chat-header" style={props.styles}>
			<div className="header-row">
				<div className="ideabot-avatar-container">
					<img
						src={avatar_a}
						alt="avatar"
						className="ideabot-chat-message-avatar"
					/>
				</div>
				<p>{props.heading}</p>
			</div>
			<img
				src={isSwitchOn ? closeBtn : resizeIcon}
				alt="close chat"
				onClick={props.handleToggle}
				className="close-chat"
			/>
		</div>
	);
};

export default ChatHeader;
