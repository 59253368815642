import { getData } from "../utills/local-storage";
import { STORAGE_KEYS } from "../utills/enums";
import { AuthRes } from "./types/index";
import { APIConstants } from "./api-constant";
import { APIHelper } from "./api-helpers";

export class ChatService {
	static async GetChatBotResponse(
		questionData: {
			question: string;
		},
		sessionId: string | null,
	): Promise<any> {
		const token = "	"; // await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
			sessionId: sessionId || null,
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(`${APIConstants.API_CHATBOT_RESPONSE}`, questionData)
				.then((res) => {
					const data = res.data;
					if (res.status === 200) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
