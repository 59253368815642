import React, { useEffect, useState } from "react";
import "./styles.scss";
import avatar_a from "../../images/Ideabot-user_new.png";
import avatar_b from "../../images/Ideabot-avatar_bot.png";
import TextTypewriter from "../Typewriter";
import Chart from "../chart";
import { some } from "d3-array";

const replaceNewLines = (str: string) => str.replace(/[\r\n]/gm, "");

type Props = {
	index: number;
	isUser: boolean;
	text: string;
	newMessage: {
		historyItem?: boolean;
	};
	renderCount: (count: number) => void;
	isFirstMessage: boolean;
	hasChatHistory: boolean;
	finishStatus: (status: boolean) => void;
	bubbleOneClick: (text: string) => void;
};

const ChatMessage = ({
	index,
	isUser,
	text,
	newMessage,
	finishStatus,
	renderCount,
	isFirstMessage,
	bubbleOneClick,
}: Props) => {
	const [renderComponent, setRenderComponent] = useState(false);

	useEffect(() => {
		if (newMessage.historyItem) {
			setRenderComponent(true);
		} else {
			const delay = 5800; // Delay in milliseconds (5.8 seconds)
			const timeoutId = setTimeout(() => {
				setRenderComponent(true);
			}, delay);
			return () => {
				clearTimeout(timeoutId); // Clean up the timeout if the component is unmounted before the delay is over
			};
		}
	}, []);

	const chunks = text.includes("*****")
		? text.split("*****")
		: text.includes("****")
		? text.split("****")
		: text.split("````");

	let sortedChunks: { type: "chart" | "text"; content: string }[] = [];

	chunks.forEach((chunk) => {
		try {
			if (replaceNewLines(chunk)[0] === "{") {
				JSON.parse(replaceNewLines(chunk));

				const lastChartIndex = sortedChunks.findLastIndex(
					(value) => value.type === "chart",
				);

				sortedChunks = [
					...sortedChunks.slice(0, lastChartIndex + 1),
					{
						type: "chart",
						content: chunk,
					},
				];
			} else {
				sortedChunks.push({
					type: "text",
					content: chunk,
				});
			}
		} catch (e) {
			sortedChunks.push({
				type: "text",
				content: chunk,
			});
		}
	});

	const hasChart = sortedChunks.some((chunk) => chunk.type === "chart");

	const sortedChunkContent = sortedChunks.map(({ content }) => content);

	return (
		<div className="ideabot-chat-message-wrapper">
			<div
				key={index}
				className={
					isUser
						? "ideabot-user-message-row"
						: "ideabot-bot-message-row"
				}
			>
				<div className="ideabot-avatar-wrapper">
					<img
						src={isUser ? avatar_a : avatar_b}
						alt="avatar"
						className="ideabot-chat-message-avatar"
					/>
				</div>
				<div
					className={
						isUser
							? "ideabot-user-message"
							: hasChart
							? "ideabot-bot-chart-message"
							: "ideabot-bot-message"
					}
				>
					{isUser || newMessage.historyItem ? (
						<p>{text}</p>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							{sortedChunkContent.map((chunk) => {
								if (replaceNewLines(chunk.trim())[0] === "{") {
									const json = JSON.parse(
										replaceNewLines(chunk),
									);

									return (
										<div style={{ alignItems: "center" }}>
											<p>
												<Chart
													type={json.type}
													config={json.data}
													finishStatus={finishStatus}
												/>
											</p>
										</div>
									);
								}
								return (
									<div>
										<p>
											<TextTypewriter
												text={chunk}
												finishStatus={finishStatus}
												countOfType={renderCount}
											/>
										</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			{/* {(isFirstMessage || index === 0) && renderComponent && (
        <div className="ideabot-initiative-message">
          <InitiativeBuble
            onClick={bubbleOneClick}
            text="What are your core services?"
          />
          <InitiativeBuble
            onClick={bubbleOneClick}
            text="I have a startup idea. Can you help me build my MVP?"
          />
          <InitiativeBuble
            onClick={bubbleOneClick}
            text="I’m looking to add an extended development team to my existing team. Can you help us with that?"
          />
        </div>
      )} */}
		</div>
	);
};

export default ChatMessage;
