// import { AuthRes } from "./types/auth-dtd";
import Axios, { AxiosInstance } from "axios";
import { getData, setData } from "../utills/local-storage";
import { STORAGE_KEYS } from "../utills/enums";
import { getNewAccessToken } from "../context/AuthProvider";

export class APIHelper {
	static _axiosInstance: AxiosInstance = null as any;

	static api(authData: any, params?: { [key: string]: any }): AxiosInstance {
		APIHelper._axiosInstance = Axios.create({
			baseURL: authData.baseUrl,
			timeout: 1000 * 600,
		});

		if (authData.authKey) {
			APIHelper._axiosInstance.interceptors.request.use(
				async (config: any) => {
					const token = await getData(
						`sb-${process.env.REACT_APP_SUPABASE_KEY}-auth-token`,
					);
					const accessToken = await getData(STORAGE_KEYS.USER_TOKEN);
					config.headers[
						`Authorization`
					] = `Bearer ${token.access_token}`;
					config.headers[`Content-Type`] = "application/json";
					config.headers[`Accept`] = "application/json";
					if (authData.sessionId) {
						config.headers[`session-id`] = authData.sessionId;
					}
					if (params) {
						config.params = params;
					}
					return config;
				},
				(error) => {
					Promise.reject(error);
				},
			);
		}

		// COMMENTED BECAUSE SUPABASE AUTO REFRESH MECHANISM ADDED
		// APIHelper._axiosInstance.interceptors.response.use(
		//   (response) => {
		//     return response;
		//   },
		//   async function (error) {
		//     const originalRequest = error.config;
		//     console.log(originalRequest, "originalRequest");
		//     if (error?.response?.status === 401 && !originalRequest._retry) {
		//       originalRequest._retry = true;

		//       const refreshToken = await getData(STORAGE_KEYS.REFRESH_TOKEN);

		//       const accessToken = await getNewAccessToken();
		//       setData(STORAGE_KEYS.USER_TOKEN, accessToken);

		//       APIHelper._axiosInstance.defaults.headers.common[
		//         "Authorization"
		//       ] = `Bearer ${accessToken}`;

		//       const modifiedRequest = {
		//         ...originalRequest,
		//         headers: {
		//           ...originalRequest.headers,
		//           Authorization: `Bearer ${accessToken}`,
		//         },
		//       };

		//       await APIHelper._axiosInstance(modifiedRequest);
		//     }
		//     return Promise.reject(error);
		//   }
		// );

		return this._axiosInstance;
	}
}
