import React from "react";
import LoginLogo from "../../assets/images/Logo_Dark.png";

type PropsObject = {
  children: React.ReactNode;
  logout: React.MouseEventHandler<HTMLButtonElement>;
};
const DashboardLayout = ({ children, logout }: PropsObject) => {
  return (
    <body className="relative bg-yellow-50 overflow-hidden max-h-screen left-side-bar">
      {/* <aside className="fixed inset-y-0 left-0 bg-white shadow-md max-h-screen w-60">
        <div className="flex flex-col justify-between h-full">
          <div className="flex-grow logo">
            <div className="text-center flex flex-col items-center justify-center py-4">
              <img src={LoginLogo} alt="logo" />
            </div>
            <div className="p-4"></div>
          </div>
          <div className="p-4">
            <button
              onClick={logout}
              type="button"
              className="inline-flex items-center justify-center h-9 px-4 rounded-xl bg-gray-900 text-gray-300 hover:text-white text-sm font-semibold transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                className=""
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </button>{" "}
            <span className="font-bold text-sm ml-2">Logout</span>
          </div>
        </div>
      </aside> */}
      <header className="sticky flex flex-row">
        <div className="justify-left">
          <img src={LoginLogo} alt="logo" />
        </div>
        <div className="flex flex-1">
          <label>Email: roshen@ideabits.se</label>
        </div>
      </header>

      <main className="max-h-screen overflow-auto admin-content h-screen">
        <div className="px-8 py-8">
          <div className="max-w-4xl flex flex-col items-start ">{children}</div>
        </div>
      </main>
    </body>
  );
};

export default DashboardLayout;
