import React, { useRef, useEffect } from "react";
import { motion, useCycle } from "framer-motion";
import Logo from "../../assets/images/logo-ideabits.png";
import Buttons from "../button";

type MenuToggleProps = {
	toggle: React.MouseEventHandler<HTMLButtonElement>;
};
type PathProps = React.ComponentProps<(typeof motion)["path"]>;

const Path = (props: PathProps) => (
	<motion.path
		fill="transparent"
		strokeWidth="3"
		stroke="hsl(0, 100%, 100%)"
		strokeLinecap="round"
		{...props}
	/>
);

const sidebar = {
	open: (height = 1000) => ({
		clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
	}),
	closed: {
		clipPath: "circle(0px at 0px 0px)",
		transition: {
			delay: 0,
			type: "spring",
			stiffness: 400,
			damping: 30,
		},
	},
};

const variants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
		},
	},
	closed: {
		y: 0,
		opacity: 0,
		transition: {
			y: { stiffness: 0 },
		},
	},
};

export const useDimensions = (
	ref: React.MutableRefObject<{ offsetWidth: number; offsetHeight: number }>,
) => {
	const dimensions = useRef({ width: 0, height: 0 });

	useEffect(() => {
		dimensions.current.width = ref.current.offsetWidth;
		dimensions.current.height = ref.current.offsetHeight;
	}, []);

	return dimensions.current;
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];
const variantss = {
	open: {
		transition: { staggerChildren: 0.07, delayChildren: 0.2 },
	},
	closed: {
		transition: {
			staggerChildren: 0.05,
			staggerDirection: -1,
			delayChildren: -20,
		},
	},
};

const MenuToggle = ({ toggle }: MenuToggleProps) => (
	<button onClick={toggle} className="toggle-btn">
		<svg width="23" height="19" viewBox="0 0 23 19">
			<Path
				variants={{
					closed: { d: "M 2 2.5 L 20 2.5" },
					open: { d: "M 3 16.5 L 17 2.5" },
				}}
			/>
			<Path
				d="M 2 9.423 L 20 9.423"
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 },
				}}
				transition={{ duration: 0.1 }}
			/>
			<Path
				variants={{
					closed: { d: "M 2 16.346 L 20 16.346" },
					open: { d: "M 3 2.5 L 17 16.346" },
				}}
			/>
		</svg>
	</button>
);

const HamBurgerMenu = ({ email, onClickContact, onClickLogout }: any) => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const { height } = useDimensions(
		containerRef as React.MutableRefObject<HTMLDivElement>,
	);
	return (
		<div className="">
			<motion.nav
				initial={false}
				animate={isOpen ? "open" : "closed"}
				custom={height}
				ref={containerRef}
				className={`${isOpen ? "open" : "closed"}`}
			>
				<motion.div className="background" variants={sidebar}>
					<div className="mobile-logo">
						<img
							src={Logo}
							className="logoImage object-scale-down"
							alt="logo"
						/>
					</div>
					{/* <Navigation onClick={() => toggleOpen()} /> */}

					<div className="button-wrapper">
						{/* <p>Username: Janithaaj@gmail.com</p> */}
						<div className="wrapper">
							<Buttons
								ButtonText="Contact us"
								onClick={onClickContact}
							/>
							<Buttons
								ButtonText="Log Out"
								onClick={onClickLogout}
							/>
						</div>

						<div className="mobile-footer">
							<p>Username: {email}</p>
						</div>
					</div>
				</motion.div>

				<MenuToggle toggle={() => toggleOpen()} />
			</motion.nav>
		</div>
	);
};

export default HamBurgerMenu;
