import React from "react";
import { motion } from "framer-motion";
import LoadingIcon from "../loading";
type ButtonsProps = {
	type?: "website" | "document";
	onClick?: () => void;
	ButtonText: string;
	transparent?: boolean;
	loading?: boolean;
	status?: number;
	ButtonType: "icon" | "normal";
	icon?: string;
	disable?: boolean;
};

const Buttons = ({
	type,
	onClick,
	ButtonText,
	transparent,
	loading,
	ButtonType,
	status,
	icon,
	disable,
}: ButtonsProps) => {
	let buttonColor = "";
	let ButtonTextStatus = "";

	if (type === "document") {
		switch (status) {
			case 0:
				buttonColor = "blue";
				ButtonTextStatus = "Uploaded";
				break;
			case 1:
				buttonColor = "#35BA72";
				ButtonTextStatus = "Processed";
				break;
			case 2:
				buttonColor = "#FF4823";
				ButtonTextStatus = "Deleted";
				break;
			case 3:
				buttonColor = "blue";
				ButtonTextStatus = "Uploading ...";
				break;
			default:
				buttonColor = "blue";
				ButtonTextStatus = ButtonText;
		}
	}

	if (type === "website") {
		switch (status) {
			case 0:
				buttonColor = "#35BA72";
				ButtonTextStatus = "Processed";
				break;
			case 1:
				buttonColor = "#FF4823";
				ButtonTextStatus = "Deleted";
				break;
			case 2:
				buttonColor = "#EDE87D";
				ButtonTextStatus = "Unprocessed";
				break;
			default:
				buttonColor = "blue";
				ButtonTextStatus = ButtonText;
		}
	}

	if (ButtonType === "normal") {
		return (
			<motion.button
				onClick={onClick}
				style={{ backgroundColor: buttonColor }}
				className="btn-status"
			>
				{loading ? <LoadingIcon /> : ButtonTextStatus}
			</motion.button>
		);
	} else {
		return (
			<motion.button
				onClick={onClick}
				className="btn-action"
				disabled={disable}
				style={{ opacity: disable ? 0.4 : 1 }}
			>
				{loading ? (
					<LoadingIcon />
				) : (
					<>
						<img src={icon as unknown as string} alt="icon" />
						<span>{ButtonText}</span>
					</>
				)}
			</motion.button>
		);
	}
};

export default Buttons;
