/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import LoginBackgroundImage from "../../assets/images/pl1.png";
import LoginLogo from "../../assets/images/logo-color.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { createClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/button";
import { STORAGE_KEYS } from "../../utills/enums";
import { setData } from "../../utills/local-storage";

type Inputs = {
	email: string;
	password: string;
	keepRemember: boolean;
};

const supabasePrivateKey = process.env.REACT_APP_SUPABASE_PRIVATE_KEY ?? "";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ?? "";

const supabase = createClient(supabaseUrl, supabasePrivateKey, {
	auth: {
		autoRefreshToken: true,
	},
});

const Login = () => {
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<Inputs>();

	const onSubmit: SubmitHandler<Inputs> = async (credential) => {
		setLoading(true);
		setError(null);
		const dataObject = {
			email: credential.email.toLowerCase(),
			password: credential.password,
		};
		const { data, error } = await supabase.auth.signInWithPassword(
			dataObject,
		);

		if (error) {
			if (error.status === 400) {
				setError(error.message);
				setLoading(false);
			}
		}
		const accessToken = data?.session?.access_token;
		const refreshToken = data?.session?.refresh_token;

		if (accessToken) {
			setLoading(false);
			await setData(STORAGE_KEYS.USER_TOKEN, accessToken);
			await setData(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
			await setData(STORAGE_KEYS.EXPIRES_AT, data.session?.expires_at);
			reset();
			setTimeout(() => {
				navigate("/preview");
			}, 500);
		}
	};

	const handleInputChange = () => {
		setError(null);
	};

	return (
		<div className="login-page">
			<div className="flex flex-row">
				<div className="login-left flex-1 min-h-screen xxxxs:hidden lg:block">
					<img
						src={LoginBackgroundImage}
						className="w-full h-full"
						alt="login-background"
					/>
				</div>

				<div className="login-right flex flex-1 flex-col justify-center items-center sm:h-screen xs:h-screen xxs:h-screen xxxs:h-screen xxxxs:h-screen">
					<div className="logo">
						<img src={LoginLogo} className="w-40" alt="login" />
					</div>
					<div className="login-form">
						<p className="form-title">Login into your account</p>

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-data">
								<label>Email ID</label>
								<input
									placeholder="yourname@company.com"
									{...register("email", {
										required: true,
										validate: {
											maxLength: (v) =>
												v.length <= 50 ||
												"The email should have at most 50 characters",
											matchPattern: (v) =>
												/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
													v,
												) ||
												"Email address must be a valid address",
										},
									})}
									onChange={handleInputChange}
								/>
								<label>Password</label>
								<input
									{...register("password", {
										required: true,
										minLength: {
											value: 6,
											message:
												"Password must be more than 6 characters",
										},
									})}
									type="password"
									placeholder="********************"
									onChange={handleInputChange}
								/>
							</div>

							<div className="password-container flex flex-row py-2">
								<div className="left flex flex-1 flex-row justify-start items-center">
									{/* <input
										type="checkbox"
										{...register("keepRemember")}
										className="mr-3"
									/>
									
									<label>Keep me signed in</label> */}
								</div>
								<div className="right flex flex-1 flex-col justify-center items-end">
									<a
										onClick={() => {
											navigate("/forgotpassword");
										}}
										className="cursor-pointer"
									>
										Forgot Password?
									</a>
								</div>
							</div>

							<div className="submit-container">
								<Buttons
									ButtonText="Sign In"
									loading={loading}
									ButtonType="auth-form"
								/>
							</div>
							<p className="error-handler">
								{error ? error : ""}
								<br />
								{errors.email && errors.email.message}
								<br />
								{errors.password && errors.password.message}
								<br />
							</p>

							<div className="login-bottom">
								<p>
									Don’t have an account?{" "}
									<a
										href="#"
										onClick={() => {
											navigate("/signup");
										}}
									>
										Register Now
									</a>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
