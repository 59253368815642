import MainRoutes from "./routes/index";
import AuthProvider from "./context/AuthProvider";

const App = () => {
	return (
		<AuthProvider>
			<MainRoutes />
		</AuthProvider>
	);
};
export default App;
