import React, { useEffect, useRef, useState } from "react";
import {
	PieChart,
	Pie,
	LineChart,
	Line,
	Cell,
	Tooltip,
	Legend,
	BarChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Bar,
	ResponsiveContainer,
} from "recharts";
import { useSwitch } from "../context/FullScreenProvider";

const COLORS = [
	"#55efc4",
	"#74b9ff",
	"#a29bfe",
	"#30336b", //
	"#ea8685",
	"#f8a5c2",
	"#e77f67",
	"#cf6a87",
	"#7ed6df",
	"#ff5252",
	"#ff7f59",
	"#66ffcc",
];

type ChartComponentProps = {
	config: {
		labels: string[];
		datasets: { label: string; data: any }[];
	};
	screenSize: {
		width: number;
		height: number;
	};
};

const CustomTooltip = (props: Record<string, unknown>) => {
	return <div>Some text</div>;
};

function getCurrentDimension() {
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
}

const formatLegendLabel = (value: string): React.ReactElement => (
	<span style={{ color: "black" }}>{value}</span>
);

const BarChartComponent = React.memo(
	({ config, screenSize }: ChartComponentProps) => {
		const { labels, datasets } = config;
		const { isSwitchOn } = useSwitch();
		const [barChartWidth, setBarChartWidth] = useState(window.innerWidth);
		const data = labels.map((label, labelIndex) => {
			let extractedData;
			if (datasets.length === 1) {
				extractedData = datasets.reduce((ac, cur, index) => {
					return {
						...ac,
						[label]: parseFloat(cur.data[labelIndex]),
					};
				}, {});
			} else {
				extractedData = datasets.reduce((ac, cur, index) => {
					return {
						...ac,
						[cur.label]: parseFloat(cur.data[labelIndex]),
					};
				}, {});
			}

			return {
				name: label,
				...extractedData,
			};
		});

		const dataKeys =
			datasets.length === 1 ? labels : datasets.map(({ label }) => label);
		const resizeMobile = () => {
			if (screenSize.width <= 768) {
				setBarChartWidth(screenSize.width - 120);
				return screenSize;
			} else if (screenSize.width <= 400) {
				setBarChartWidth(screenSize.width - 20);
				return screenSize;
			} else {
				setBarChartWidth(500);
			}
		};
		useEffect(() => {
			resizeMobile();
		}, []);
		useEffect(() => {
			resizeForMobile();
		}, [isSwitchOn]);

		const resizeForMobile = () => {
			if (isSwitchOn) {
				setBarChartWidth(850);
			} else if (window.innerWidth <= 768) {
				setBarChartWidth(300);
			} else {
				setBarChartWidth(500);
			}
		};
		return (
			<div className="flex justify-center items-center">
				<BarChart
					data={data}
					width={barChartWidth}
					height={550}
					margin={{ right: 20, top: 40, bottom: 40 }}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip
						formatter={(_1, _2, values) => {
							return `${values.value?.toLocaleString()} ${
								values.dataKey
							}`;
						}}
						wrapperStyle={{
							zIndex: 999,
							left: -50,
						}}
						// contentStyle={{ position: "absolute", zIndex: 999 }}
					/>
					<Legend formatter={formatLegendLabel} />
					{dataKeys.map((dataKey, index) => (
						<Bar dataKey={dataKey} fill={COLORS[index]} />
					))}
				</BarChart>
			</div>
		);
	},
);

const PieChartComponent = ({ config, screenSize }: ChartComponentProps) => {
	const { labels, datasets } = config;
	const [chartWidth, setChartWidth] = useState(window.innerWidth);
	const { isSwitchOn } = useSwitch();
	const resizeMobile = () => {
		if (screenSize.width <= 768) {
			setChartWidth(screenSize.width - 120);
			return screenSize;
		} else if (screenSize.width <= 400) {
			setChartWidth(screenSize.width - 20);
			return screenSize;
		} else {
			setChartWidth(500);
		}
	};
	useEffect(() => {
		resizeMobile();
	}, []);
	useEffect(() => {
		resizeForMobile();
	}, [isSwitchOn]);

	const resizeForMobile = () => {
		if (isSwitchOn) {
			setChartWidth(850);
		} else if (window.innerWidth <= 768) {
			setChartWidth(300);
		} else {
			setChartWidth(500);
		}
	};
	return (
		<PieChart width={chartWidth} height={550}>
			{datasets.map(({ data, label }, index) => {
				const extractedData = labels.map((labelName, index) => {
					return {
						name: labelName,
						[label]: data[index],
					};
				});

				return (
					<Pie
						dataKey={label}
						data={extractedData}
						// cx={200}
						// cy={200}
						innerRadius={index === 0 ? undefined : 20 + index * 5}
						outerRadius={180 + index * 5}
						fill="#8884d8"

						//label
					>
						{extractedData.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index]} />
						))}
						;
					</Pie>
				);
			})}
			<Tooltip
				formatter={(_1, _2, values) => {
					return `${values.value?.toLocaleString()} ${
						values.dataKey
					}`;
				}}
				wrapperStyle={{
					zIndex: 999,
					left: -50,
				}}
			/>
			{/* <Legend content={<CustomLegend />} /> */}
			<Legend formatter={formatLegendLabel} />
		</PieChart>
	);
};

type ChartLegendItemProps = {
	color: string;
	label: string;
};

const ChartLegendItem = ({ color, label }: ChartLegendItemProps) => {
	return (
		<div className="flex items-center mb-2">
			<div
				className="w-4 h-4 mr-2 rounded-full"
				style={{ backgroundColor: color }}
			></div>
			<span>{label}</span>
		</div>
	);
};

const CustomLegend = (props: {
	payload?: { color: string; value: string }[];
}) => {
	const payload = props?.payload ?? [];

	return (
		<div className="flex row">
			{payload.map((item, index) => (
				<ChartLegendItem
					key={index}
					color={item.color}
					label={item.value}
				/>
			))}
		</div>
	);
};

const LineChartComponent = ({ config, screenSize }: ChartComponentProps) => {
	const { labels, datasets } = config;

	const combinedData = labels.map((label, index) => {
		const dataEntry: any = { name: label };
		datasets.forEach((dataset) => {
			dataEntry[dataset.label] = dataset.data[index];
		});
		return dataEntry;
	});

	const [chartWidth, setChartWidth] = useState(window.innerWidth);
	const { isSwitchOn } = useSwitch();
	const resizeMobile = () => {
		if (screenSize.width <= 768) {
			setChartWidth(screenSize.width - 120);
			return screenSize;
		} else if (screenSize.width <= 400) {
			setChartWidth(screenSize.width - 20);
			return screenSize;
		} else {
			setChartWidth(500);
		}
	};
	useEffect(() => {
		resizeMobile();
	}, []);
	useEffect(() => {
		resizeForMobile();
	}, [isSwitchOn]);

	const resizeForMobile = () => {
		if (isSwitchOn) {
			setChartWidth(850);
		} else if (window.innerWidth <= 768) {
			setChartWidth(300);
		} else {
			setChartWidth(500);
		}
	};

	return (
		<>
			<LineChart
				width={chartWidth}
				height={550}
				margin={{ right: 20, top: 40, bottom: 40 }}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" allowDuplicatedCategory={false} />
				<YAxis />
				<Tooltip
					formatter={(value, _, { dataKey }) => {
						return `${value.toLocaleString()} ${dataKey}`;
					}}
					wrapperStyle={{
						zIndex: 999,
						left: -50,
					}}
					// contentStyle={{ width: 100 }}
				/>

				<Legend formatter={formatLegendLabel} />
				{datasets.map(({ label }, index) => (
					<Line
						key={`line-${index}`}
						type="monotone"
						dataKey={label}
						data={combinedData}
						stroke={COLORS[index % COLORS.length]}
						dot={{ r: 5 }}
					/>
				))}
			</LineChart>
		</>
	);
};

type ChartProps = {
	type: "bar" | "pie" | "line";
	config: ChartComponentProps["config"];
	finishStatus: (isStatusFinished: boolean) => void;
};

const ChartComponent = ({ type, config, finishStatus }: ChartProps) => {
	const wasCalledOnce = useRef(false);
	const [screenSize, setScreenSize] = useState(getCurrentDimension());

	useEffect(() => {
		if (wasCalledOnce.current) {
			return;
		}

		finishStatus(true);
		wasCalledOnce.current = true;
	}, [finishStatus]);

	useEffect(() => {
		const updateDimension = () => {
			setScreenSize(getCurrentDimension());
		};
		window.addEventListener("resize", updateDimension);

		return () => {
			window.removeEventListener("resize", updateDimension);
		};
	}, [screenSize]);

	switch (type) {
		case "bar":
			return (
				<ResponsiveContainer width="100%" height="100%">
					<BarChartComponent
						config={config}
						screenSize={screenSize}
					/>
				</ResponsiveContainer>
			);
		case "pie":
			return (
				<ResponsiveContainer width="90%" height="100%">
					<PieChartComponent
						config={config}
						screenSize={screenSize}
					/>
				</ResponsiveContainer>
			);
		case "line":
			return (
				<ResponsiveContainer width="100%" height="100%">
					<LineChartComponent
						config={config}
						screenSize={screenSize}
					/>
				</ResponsiveContainer>
			);
		default:
			throw new Error("Unsupported Chart Type");
	}
};

export default ChartComponent;
