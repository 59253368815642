import React from "react";
import { motion } from "framer-motion";
import LoadingIcon from "../loading";
type ButtonsProps = {
  onClick?: () => void;
  ButtonText: string;
  ButtonStyle?: object;
  transparent?: boolean;
  loading?: boolean;
  ButtonType?: "auth-form" | "normal";
};

const Buttons = ({
  onClick,
  ButtonText,
  ButtonStyle,
  transparent,
  loading,
  ButtonType = "normal",
}: ButtonsProps) => {
  return (
    <motion.button
      onClick={onClick}
      style={ButtonStyle}
      className={`primary-btn flex flex-col items-center justify-center ${
        transparent && "transparent"
      } ${ButtonType}`}
      type="submit"
    >
      {loading ? <LoadingIcon /> : ButtonText}
    </motion.button>
  );
};

export default Buttons;
