import { APIConstants } from "./api-constant";
import { APIHelper } from "./api-helpers";
import { AuthRes } from "./types/index";
import { getData } from "../utills/local-storage";
import { STORAGE_KEYS } from "../utills/enums";

interface documentNames {
	documentNames: object;
}

interface webUrlList {
	urls: Array<any>;
}

interface documentNamesList {
	documentIds: Array<any>;
}

export class DocumentService {
	static async GetDocuments(): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.get<any>(`${APIConstants.API_GET_DOCUMENTS}`)
				.then((res) => {
					const data = res.data;

					if (res.status === 200) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
	static async GetDocumentsSignUrl(
		documentNames: documentNames,
	): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(
					`${APIConstants.API_POST_DOCUMENT_SIGN_URL}`,
					documentNames,
				)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async DocumentUpdateBackend(
		documentNames: documentNames,
	): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(
					`${APIConstants.API_POST_DOCUMENT_UPDATE_BACKEND}`,
					documentNames,
				)
				.then((res) => {
					const data = res.data;
					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async DeleteDocument(documentIds: documentNamesList): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(`${APIConstants.API_DELETE_DOCUMENT}`, documentIds)
				.then((res) => {
					const data = res.data;
					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
	static async ProcessDocument(documentIds: documentNamesList): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(`${APIConstants.API_DOCUMENT_PROCESS}`, documentIds)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
	static async ProcessWebUrl(documentNamesList: webUrlList): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(
					`${APIConstants.API_WEB_URL_PROCESS}`,
					documentNamesList,
				)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async ProcessFailedWebUrl(
		documentNamesList: webUrlList,
	): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.post<any>(
					`${APIConstants.API_FAILED_WEB_URL_PROCESS}`,
					documentNamesList,
				)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async GetWebSite(): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.get<any>(`${APIConstants.API_GET_WEB_SITE}`)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async ListWebUrl(): Promise<any> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const authBody: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};
		return new Promise<any>((resolve, reject) => {
			APIHelper.api(authBody)
				.get<any>(`${APIConstants.API_GET_WEB_URLS}`)
				.then((res) => {
					const data = res.data;

					if (res.status === 201) {
						resolve(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async getSubUrls(baseUrl: string): Promise<string[]> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);

		const apiConfig: AuthRes = {
			baseUrl: APIConstants.BASE_URL_PREPROCESSOR,
			authKey: token,
			authType: "Bearer",
		};

		const response = await APIHelper.api(apiConfig).post<string[]>(
			APIConstants.API_GET_WEB_URLS,
			{
				baseUrl,
			},
		);

		return response.data;
	}

	static async deleteUrls(websiteIds: object): Promise<void> {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		const apiConfig: AuthRes = {
			baseUrl: APIConstants.BASE_URL,
			authKey: token,
			authType: "Bearer",
		};
		await APIHelper.api(apiConfig).post<string[]>(
			APIConstants.API_DELETE_WEB_SITE,
			{
				websiteIds,
			},
		);
	}
}
