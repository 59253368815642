import React from "react";

const ErrorPage = ({ resetBoundary }) => {
	return (
		<div
			role="alert"
			className="error-container flex flex-col justify-center items-center h-screen"
		>
			<div className="logo-wrapper">
				<img
					src={require("../../assets/images/logo-color.png")}
					alt="logo"
				/>
			</div>
			<div className="error-wrapper">
				<img
					src={require("../../assets/images/error-boundry.gif")}
					alt="error"
				/>
			</div>
			<p className="error-message">Oops! Something went wrong.</p>
			<div className="error-instruction">
				<p>An error has occurred, Here are a few things you can try:</p>

				<ul>
					<li>1. Refresh the page</li>
					<li>2. Check your internet connection</li>
					<li>3. Wait a few seconds and try again</li>
				</ul>

				<p>
					If the problem persists, send us an email at{" "}
					<a
						href="mailto:ai@ideabits.se"
						target="_blank"
						rel="noreferrer"
					>
						ai@ideabits.se
					</a>
				</p>
			</div>
			<button onClick={resetBoundary} className="primary-btn">
				Try Again!
			</button>
		</div>
	);
};

export default ErrorPage;
