import React, { useState, useEffect, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/home";
import Preview from "../pages/preview";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import SignUp from "../pages/signup";
import ForgotPassowrd from "../pages/fogotPassword";
import ResetPassword from "../pages/resetpassword";
import EmailConfirmationPage from "../pages/emailconfirmation";
import { getData } from "../utills/local-storage";
import { STORAGE_KEYS } from "../utills/enums";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import AuthRoute from "./authRoute";

const MainRoutes = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const route = async () => {
		const token = await getData(STORAGE_KEYS.USER_TOKEN);
		// if (token == null && user) {
		// 	navigate("/signin");
		// }
	};

	useEffect(() => {
		route();
	}, []);

	return (
		<Routes>
			<Route element={<AuthRoute />}>
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/preview" element={<Preview />} />
			</Route>
			<Route path="/" element={<Login />} />
			<Route path="/confirm" element={<EmailConfirmationPage />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/signin" element={<Login />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/forgotpassword" element={<ForgotPassowrd />} />
			<Route path="/resetpassword" element={<ResetPassword />} />
		</Routes>
	);
};

export default MainRoutes;
