import React, { useState } from "react";
import LoginBackgroundImage from "../../assets/images/pl1.png";
import LoginLogo from "../../assets/images/logo-color.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { createClient } from "@supabase/supabase-js";
import Buttons from "../../components/button";
import { CustomerService } from "../../services/customer-service";
import { useNavigate } from "react-router-dom";

type Inputs = {
	email: string;
	password: string;
};

const supabasePrivateKey = process.env.REACT_APP_SUPABASE_PRIVATE_KEY ?? "";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ?? "";
const supabase = createClient(supabaseUrl, supabasePrivateKey);

const SignUp = () => {
	const [error, setError] = useState<string | null>(null);
	const [successful, setSuccessful] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const onSubmit: SubmitHandler<Inputs> = async (credential) => {
		setLoading(true);
		setSuccessful(null);
		setError(null);
		const dataObject = {
			email: credential.email.toLowerCase(),
			password: credential.password,
			options: {
				emailRedirectTo: `${process.env.REACT_APP_WEB_URL}/confirm`,
			},
		};
		const { data, error } = await supabase.auth.signUp(dataObject);

		if (
			!error &&
			data &&
			data?.user?.identities?.length &&
			data?.user?.identities?.length > 0
		) {
			try {
				await CustomerService.create({
					email: data.user?.email as string,
				});
			} catch (error: any) {
				setError(error?.response?.data?.message);
				setLoading(false);
			}

			setSuccessful(
				"Registration Successful. Check your email to confirm your account",
			);
			setLoading(false);
		}

		if (data.user?.identities?.length === 0) {
			setError("User already registered");
			setLoading(false);
		}
	};

	const handleInputChange = () => {
		setError(null);
		setSuccessful(null);
	};

	return (
		<div className="login-page">
			<div className="flex flex-row">
				<div className="login-left flex-1 min-h-screen xxxxs:hidden lg:block">
					<img
						src={LoginBackgroundImage}
						className="w-full h-full"
						alt="login-background"
					/>
				</div>
				<div className="login-right flex flex-1 flex-col justify-center items-center sm:h-screen xs:h-screen xxs:h-screen xxxs:h-screen xxxxs:h-screen">
					<div className="logo">
						<img src={LoginLogo} className="w-40" alt="login" />
					</div>
					<div className="login-form">
						<p className="form-title">Sign up your account</p>

						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-data">
								<label>Email ID</label>
								<input
									placeholder="yourname@company.com"
									{...register("email", {
										required: true,
										validate: {
											maxLength: (v) =>
												v.length <= 50 ||
												"The email should have at most 50 characters",
											matchPattern: (v) =>
												/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
													v,
												) ||
												"Email address must be a valid address",
										},
									})}
									onChange={handleInputChange}
								/>
								<label>Password</label>
								<input
									type="password"
									{...register("password", {
										required: "You must specify a password",
										minLength: {
											value: 6,
											message:
												"Password must have at least 6 characters",
										},
									})}
									placeholder="********************"
									onChange={handleInputChange}
								/>
							</div>

							<div className="submit-container">
								<Buttons
									ButtonText="Sign Up"
									loading={loading}
									ButtonType="auth-form"
								/>
							</div>
							<p className="error-handler">
								{errors && <span>{error}</span>}
								<br />
								{errors.email && errors.email.message}
								<br />
								{errors.password && errors.password.message}
							</p>
							<p className="sucussfull-hanlde">
								{successful && successful}
							</p>
							<div className="login-bottom">
								<p>
									If you already have an account?{" "}
									<a
										href="#"
										onClick={() => {
											navigate("/signin");
										}}
									>
										Sign In
									</a>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
