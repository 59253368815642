import { createContext, useContext } from "react";

type AuthContextValues = {
  isNewUser: boolean;
  setIsNewUser: (isNewUser: boolean) => void;
};

export const AuthContext = createContext<AuthContextValues>({
  isNewUser: false,
  setIsNewUser: (_) => {},
});

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("Component is not a consumer of Auth Context");
  }

  return authContext;
};
