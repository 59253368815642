import { STORAGE_KEYS } from "../utills/enums";
import { getData } from "../utills/local-storage";
import { APIConstants } from "./api-constant";
import { APIHelper } from "./api-helpers";

type CreateCustomerPayload = {
  email: string;
};

export type GetLoggedInCustomerPayload = {
  customer: {
    email: string;
  };
  documents: {
    id: number;
    name: string;
    status: number;
  }[];
  websites: {
    id: number;
    url: string;
    status: number;
    createdAt: Date;
  }[];
};

export class CustomerService {
  static async create(
    createCustomerPayload: CreateCustomerPayload
  ): Promise<any> {
    const apiKey = process.env["REACT_APP_API_KEY"];
    const headers = {
      "x-api-key": apiKey,
    };

    const response = await APIHelper.api({
      baseUrl: APIConstants.BASE_URL,
    }).post<GetLoggedInCustomerPayload>(
      `${APIConstants.API_CUSTOMER}`,
      createCustomerPayload,
      {
        headers,
      }
    );

    return response.data;
  }

  static async getLoggedInCustomer(): Promise<GetLoggedInCustomerPayload> {
    const token = await getData(STORAGE_KEYS.USER_TOKEN);

    const response = await APIHelper.api({
      baseUrl: APIConstants.BASE_URL,
      authKey: token,
      authType: "Bearer",
    }).get<GetLoggedInCustomerPayload>(
      `${APIConstants.API_GET_LOGGED_IN_CUSTOMER}`
    );

    return response.data;
  }
}
