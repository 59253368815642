export class APIConstants {
  /**
   * BASE_URL
   */
  static BASE_URL = process.env.REACT_APP_CHAT_API as string;

  static BASE_URL_PREPROCESSOR = process.env
    .REACT_APP_PRE_PROCESSOR_API as string;
  /**
   * Sign-in endpoints
   */
  static API_POST_LOGIN = "/auth/sign-in";

  /**
   * Create customer endpoints
   */
  static API_CREATE_CUSTOMER = "/customer";

  /**
   * Get Customer Documents
   */
  static API_CUSTOMER = "/customer";
  static API_GET_LOGGED_IN_CUSTOMER = "/customer/logged-in-customer";
  static API_GET_DOCUMENTS = "/customer/logged-in-customer";
  static API_POST_DOCUMENT_SIGN_URL = "/documents/presigned-put-url";
  static API_POST_DOCUMENT_UPDATE_BACKEND = "/documents/";
  static API_DELETE_DOCUMENT = "/documents/delete";
  static API_DOCUMENT_PROCESS = "/pre-processor/process-documents";
  static API_WEB_URL_PROCESS = "/pre-processor/process-urls/";
  static API_FAILED_WEB_URL_PROCESS = "/pre-processor/process-failed-urls";
  static API_GET_WEB_URLS = "/pre-processor/relative-urls/";
  static API_GET_WEB_SITE = "/websites/";
  static API_DELETE_WEB_SITE = "/websites/delete";
  static API_CHATBOT_RESPONSE = "/chat";
}
