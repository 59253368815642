import { AuthError, User } from "@supabase/supabase-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../superbase/client";
import { getData, setData } from "../utills/local-storage";
import { STORAGE_KEYS } from "../utills/enums";

type AuthContextValues = {
	auth: boolean;
	user: User | null;
	login: (email: string, password: string) => void;
	signOut: () => Promise<{ error: AuthError | null }>;
	passwordReset: (email: string) => void;
	updatePassword: (updatedPassword: string) => void;
};

const AuthContext = createContext({} as unknown as AuthContextValues);

export const useAuth = () => useContext(AuthContext);

const login = (email: string, password: string) => {
	supabase.auth.signInWithPassword({ email, password });
};

export const getNewAccessToken = async () => {
	const refreshToken = await getData(STORAGE_KEYS.REFRESH_TOKEN);

	if (!refreshToken) {
		return;
	}

	const newSession = await supabase.auth.refreshSession({
		refresh_token: refreshToken,
	});

	setData(STORAGE_KEYS.USER_TOKEN, newSession.data.session?.access_token);
	setData(STORAGE_KEYS.REFRESH_TOKEN, newSession.data.session?.refresh_token);

	return newSession.data.session?.access_token;
};

const signOut = () => supabase.auth.signOut();

const passwordReset = (email: string) => {
	supabase.auth.resetPasswordForEmail(email, {
		redirectTo:
			"http://ideabot-landing-page.s3-website.eu-north-1.amazonaws.com/resetpassword",
	});
};

const updatePassword = (updatedPassword: string) => {
	supabase.auth.updateUser({ password: updatedPassword });
};

const AuthProvider = ({ children }: { children: any }) => {
	const [auth, setAuth] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const getUser = async () => {
			const { data } = await supabase.auth.getUser();
			const { user: currentUser } = data;
			setUser(currentUser ?? null);
			setAuth(currentUser ? true : false);
			setLoading(false);
		};
		getUser();
		const { data } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				if (event === "PASSWORD_RECOVERY") {
					setAuth(false);
				} else if (event === "SIGNED_IN") {
					setUser(session?.user as User);
					setAuth(true);
				} else if (event === "SIGNED_OUT") {
					setAuth(false);
					setUser(null);
				}
			},
		);
		return () => {
			data.subscription.unsubscribe();
		};
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				user,
				login,
				signOut,
				passwordReset,
				updatePassword,
			}}
		>
			{!loading && children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
