import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the type for the context value
interface SwitchContextValue {
	isSwitchOn: boolean;
	toggleSwitch: () => void;
}

// Create a context for the switch state
const SwitchContext = createContext<SwitchContextValue | undefined>(undefined);

// Create a custom hook to access the context
export const useSwitch = (): SwitchContextValue => {
	const context = useContext(SwitchContext);
	if (context === undefined) {
		throw new Error("useSwitch must be used within a SwitchProvider");
	}
	return context;
};

// Define the type for the SwitchProvider props
interface SwitchProviderProps {
	children: ReactNode;
}

// Provide the context at the top-level of your application
export function SwitchProvider({ children }: SwitchProviderProps): JSX.Element {
	const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);

	const toggleSwitch = (): void => {
		setIsSwitchOn((prev) => !prev);
	};

	return (
		<SwitchContext.Provider value={{ isSwitchOn, toggleSwitch }}>
			{children}
		</SwitchContext.Provider>
	);
}
