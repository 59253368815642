import React from "react";
import poweredByLogo from "../../images/powered_by.png";
import "./styles.scss";

const Footer = () => {
  return (
    <div className="ideabot-footer">
      <img
        src={poweredByLogo}
        alt="avatar"
        className="ideabot-chat-footer-powered-by"
      />
      <p className="ideabot-chat-footer-version">Beta V1.0</p>
    </div>
  );
};

export default Footer;
