import React, { useState, useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";

type Props = {
  text: string;
  finishStatus: (status: boolean) => void;
  countOfType: (count: number) => void;
};

const TextTypewriter = ({ text, finishStatus, countOfType }: Props) => {
  const [renderCount, setRenderCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const maxLength = text.length;
    const statusOfTyping = renderCount === maxLength;
    setCount(renderCount);
    if (statusOfTyping) {
      finishStatus(statusOfTyping);
      setRenderCount(0);
      setCount(0);
    }
  }, [renderCount, text, finishStatus]);

  const handleTypingComplete = () => {
    setRenderCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    countOfType(count);
  }, [count, countOfType]);

  return (
    <Typewriter
      words={[text]}
      cursor
      loop={1}
      cursorStyle=""
      typeSpeed={20}
      onType={handleTypingComplete}
    />
  );
};

export default TextTypewriter;
