import * as React from "react";

export const TabSelector = ({
	isActive,
	children,
	onClick,
}: {
	isActive: boolean;
	children: React.ReactNode;
	onClick: () => void;
}) => (
	<button
		className={`${
			isActive ? "border-b" : ""
		} mr-8 group inline-flex items-center  font-medium text-sm leading-5 cursor-pointer whitespace-nowrap `}
		onClick={onClick}
	>
		{children}
	</button>
);
