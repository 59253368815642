import { useState, useRef, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import './styles.scss';
import send_btn from "../../images/Send-Btn.png";

type Props = {
  isDisabled: boolean;
  handleSendMessageClick: (text: string) => void;
}


function ChatInput(props: Props) {

  const [inputValue, setInputValue] = useState("");
  const textInput = useRef<HTMLTextAreaElement | null>(null);
  const inputDiv = useRef<HTMLDivElement | null>(null);
  const btnDiv = useRef<HTMLButtonElement | null>(null);

  const isInputEmpty = () => {
    if (props.isDisabled || inputValue.length <= 1) {
      return true;
    }
    return inputValue.length === 0;
  };

  const handleSendMessageClick = (e: React.FormEvent) => {
    e.preventDefault();
    props.handleSendMessageClick(inputValue)
    setInputValue("");
  }

  useEffect(() => {
    if (!props.isDisabled && !isMobile) {
      textInput.current?.focus();
    }    
  }, [props.isDisabled ]);

  useEffect(() => {
    if (!textInput.current || !inputDiv.current || !btnDiv.current) {
      return;
    }

    textInput.current.style.height = "20px";
    inputDiv.current.style.height = "40px";
    btnDiv.current.style.height = "37px";
    const scrollHeight = textInput.current.scrollHeight;
    if (inputValue && inputValue.length > 46 && scrollHeight < 62) {
      textInput.current.style.height = scrollHeight + "px";
      inputDiv.current.style.height = `${scrollHeight + 20}px`;
      btnDiv.current.style.height = `${scrollHeight + 17}px`;
    } else {
      if (inputValue && inputValue.length > 46) {
        textInput.current.style.height = "62px";
        inputDiv.current.style.height = "82px";
        btnDiv.current.style.height = "79px";
      }
    }    
}, [inputValue]);

const enterPress = (e: React.KeyboardEvent) => {
  if (e.keyCode === 13 && e.shiftKey === false) {
    e.preventDefault();
    handleSendMessageClick(e);
  }}

  return (
    <div className="ideabot-chat-input" ref={inputDiv}>
      <form onSubmit={handleSendMessageClick}>
          <textarea
            placeholder="Type your message here..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="user-input"
            disabled={props.isDisabled}
            ref={textInput}
            onKeyDown={enterPress}
          />
        <button disabled={isInputEmpty()} type="submit" ref={btnDiv}>
          <img
            src={send_btn}
            alt="avatar"
            className="ideabot-chat-message-avatar"
          />
        </button>
      </form>

    </div>
  );
}

export default ChatInput;