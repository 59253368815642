import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/dashboard-layout";
import { useForm } from "react-hook-form";
import Buttons from "../../components/button/index";
import ButtonStatus from "../../components/button/button-one";
import ProcessIcon from "../../assets/images/process.png";
import TrashIcon from "../../assets/images/trash.png";
import { DocumentService } from "../../services/document-service";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/tabView";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { STORAGE_KEYS } from "../../utills/enums";
import { removeData } from "../../utills/local-storage";

const Dashboard = () => {
	const { signOut, user } = useAuth();

	const navigate = useNavigate();
	const [documentList, setDocumentList] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		getDocuments();
	}, []);

	const getDocuments = async () => {
		try {
			const documentResponse = await DocumentService.GetDocuments();

			setDocumentList(documentResponse.documents);
		} catch (error) {}
	};
	const { register, handleSubmit, reset } = useForm();

	const onSubmit = () => {};

	const onSubmitFile = async (data) => {
		setLoading(true);
		const formData = new FormData();
		formData.append("file", data.file[0]);
		const fileName = data.file[0].name;

		const fileNames = {
			documentNames: [fileName],
		};

		try {
			const response = await DocumentService.GetDocumentsSignUrl(
				fileNames,
			);
			await fetch(response[0].url, {
				method: "PUT",
				headers: {
					"Content-Type": data.file[0].type,
				},
				body: data.file[0],
			})
				.then(async (res) => {
					if (res.status === 200) {
						try {
							const documentUpdate =
								await DocumentService.DocumentUpdateBackend(
									fileNames,
								);
							if (
								documentUpdate.length &&
								documentUpdate.length > 0
							) {
								toast.success(
									"Documents uploaded sucessfully!",
									{
										position: "top-center",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: "light",
									},
								);
								reset();
								setLoading(false);
								getDocuments();
							}
						} catch (error) {}
					}
				})
				.then((ddd) => {});
		} catch (error) {}
	};
	const [selectedTab, setSelectedTab] = useTabs(["files", "web"]);

	const handleLogout = async () => {
		try {
			await signOut();
			navigate("/signin");
			await removeData(STORAGE_KEYS.USER_TOKEN);
		} catch (error) {}
	};

	const DeleteFile = async (name) => {
		const fileNames = {
			documentNames: [name],
		};

		try {
			await DocumentService.DeleteDocument(fileNames);
		} catch (error) {
			toast.error(error.response?.statusText, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};

	return (
		<DashboardLayout logout={handleLogout}>
			<div className="title">
				<h2>Manage sources</h2>
			</div>
			<nav className="flex ">
				<TabSelector
					isActive={selectedTab === "files"}
					onClick={() => setSelectedTab("files")}
				>
					Files
				</TabSelector>
				<TabSelector
					isActive={selectedTab === "web"}
					onClick={() => setSelectedTab("web")}
				>
					Web
				</TabSelector>
			</nav>
			<div className="flex py-8">
				<TabPanel hidden={selectedTab !== "files"}>
					<div className="file-upload flex flex-col items-start justify-start">
						<form
							onSubmit={handleSubmit(onSubmitFile)}
							className="flex flex-col"
						>
							<label>Upload</label>
							<div className="flex flex-row items-center justify-center">
								<input
									id="file-upload"
									type="file"
									{...register("file", {
										validate: {
											lessThan10MB: (files) =>
												files[0]?.size <
													50 * 1024 * 1024 ||
												"Max 50MB",
										},
									})}
									className="mr-5"
									accept=".pdf,.doc,.docx"
								/>
								<Buttons
									ButtonText="Upload File"
									ButtonType="auth-form"
									ButtonStyle={{
										width: 150,
										height: 45,
										backgroundColor: "#040044",
										border: "none",
										color: "white",
										fontSize: 12,
										marginTop: 0,
									}}
									loading={loading}
								/>
							</div>
						</form>
					</div>

					<div className="file-upload-table">
						<p className="table-header">Existing Sources</p>
						<div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
							<table className="min-w-full leading-normal bg-white">
								<thead>
									<tr>
										<th className="px-5 py-3 text-left  tracking-wider">
											File Name
										</th>
										<th className="px-5 py-3 bg-gray-100 text-center tracking-wider">
											Date
										</th>
										<th className="px-5 py-3 text-center  tracking-wider">
											Status
										</th>
										<th className="px-5 py-3 text-center tracking-wider">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{documentList.length &&
									documentList.length > 0
										? documentList.map((item, index) => (
												<tr>
													<td className="px-5 py-5  bg-white text-sm">
														<div className="flex items-center">
															<span>
																{item.name}
															</span>
														</div>
													</td>
													<td className="px-5 py-5 bg-white text-sm">
														<p className="text-gray-900 whitespace-no-wrap">
															27/05/2023
														</p>
													</td>
													<td className="px-5 py-5 bg-white text-sm">
														<ButtonStatus
															ButtonText="Save"
															ButtonType="normal"
															status={item.status}
														/>
													</td>
													<td className="px-5 py-5  bg-white flex flex-row">
														<ButtonStatus
															ButtonText="Process"
															ButtonType="icon"
															icon={ProcessIcon}
															disable={false}
														/>
														<ButtonStatus
															ButtonText="Delete"
															ButtonType="icon"
															icon={TrashIcon}
															// disable={true}
															onClick={() =>
																DeleteFile(
																	item.name,
																)
															}
														/>
													</td>
												</tr>
										  ))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</TabPanel>
				<TabPanel hidden={selectedTab !== "web"}>
					<div className="web-url-container">
						<form>
							<label>Website URL</label>
							<p>
								Include your website URL here to extract data.
								This URL will crawl all the data of your
								website.
							</p>
							<div className="input-wrapper flex flex-row">
								<input type="text" placeholder="www.test.com" />
								<Buttons
									ButtonText="Save"
									ButtonType="auth-form"
								/>
							</div>
						</form>
					</div>

					<div className="file-upload-table">
						<p className="table-header">Existing Sources</p>
						<div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
							<table className="min-w-full leading-normal bg-white">
								<thead>
									<tr>
										<th className="px-5 py-3 text-left  tracking-wider">
											File Name
										</th>
										<th className="px-5 py-3 bg-gray-100 text-center tracking-wider">
											Date
										</th>
										<th className="px-5 py-3 text-center  tracking-wider">
											Status
										</th>
										<th className="px-5 py-3 text-center tracking-wider">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="px-5 py-5  bg-white text-sm">
											<div className="flex items-center">
												<span>File 1</span>
											</div>
										</td>
										<td className="px-5 py-5 bg-white text-sm">
											<p className="text-gray-900 whitespace-no-wrap">
												27/05/2023
											</p>
										</td>
										<td className="px-5 py-5 bg-white text-sm">
											<ButtonStatus
												ButtonText="Save"
												ButtonType="normal"
												status={0}
											/>
										</td>
										<td className="px-5 py-5  bg-white flex flex-row">
											<ButtonStatus
												ButtonText="Process"
												ButtonType="icon"
												icon={ProcessIcon}
												status={0}
												disable={false}
											/>
											<ButtonStatus
												ButtonText="Delete"
												ButtonType="icon"
												icon={TrashIcon}
												status={0}
												disable={true}
											/>
										</td>
									</tr>
									<tr>
										<td className="px-5 py-5  bg-white text-sm">
											<div className="flex items-center">
												<span>File 1</span>
											</div>
										</td>
										<td className="px-5 py-5 bg-white text-sm">
											<p className="text-gray-900 whitespace-no-wrap">
												27/05/2023
											</p>
										</td>
										<td className="px-5 py-5 bg-white text-sm ">
											<ButtonStatus
												ButtonText="Save"
												ButtonType="normal"
												status={1}
											/>
										</td>
										<td className="px-5 py-5  bg-white  flex flex-row ">
											<ButtonStatus
												ButtonText="Process"
												ButtonType="icon"
												icon={ProcessIcon}
												status={0}
												disable={true}
											/>
											<ButtonStatus
												ButtonText="Delete"
												ButtonType="icon"
												icon={TrashIcon}
												status={0}
												disable={false}
											/>
										</td>
									</tr>
									<tr>
										<td className="px-5 py-5  bg-white text-sm">
											<div className="flex items-center">
												<span>File 1</span>
											</div>
										</td>
										<td className="px-5 py-5 bg-white text-sm">
											<p className="text-gray-900 whitespace-no-wrap">
												27/05/2023
											</p>
										</td>
										<td className="px-5 py-5 bg-white text-sm ">
											<ButtonStatus
												ButtonText="Save"
												ButtonType="normal"
												status={0}
											/>
										</td>
										<td className="px-5 py-5  bg-white  flex flex-row ">
											<ButtonStatus
												ButtonText="Process"
												ButtonType="icon"
												icon={ProcessIcon}
												disable={false}
												loading={false}
											/>
											<ButtonStatus
												ButtonText="Delete"
												ButtonType="icon"
												icon={TrashIcon}
												disable={false}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</TabPanel>
			</div>
		</DashboardLayout>
	);
};

export default Dashboard;
