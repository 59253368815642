import React from "react";
import LoginBackgroundImage from "../../assets/images/pl1.png";
import LoginLogo from "../../assets/images/logo-color.png";
import Buttons from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../services/auth-context";

function EmailConfirmationPage() {
	const navigate = useNavigate();

	const { setIsNewUser } = useAuthContext();

	const signIn = () => {
		setIsNewUser(true);
		navigate("/signin");
	};

	return (
		<div className="login-page">
			<div className="flex flex-row">
				<div className="login-left flex-1 min-h-screen xxxxs:hidden lg:block">
					<img
						src={LoginBackgroundImage}
						className="w-full h-full"
						alt="login-background"
					/>
				</div>

				<div className="login-right flex flex-1 flex-col justify-center items-center sm:h-screen xs:h-screen xxs:h-screen xxxs:h-screen xxxxs:h-screen">
					<div className="logo">
						<img src={LoginLogo} className="w-40" alt="login" />
					</div>
					<div className="login-form flex flex-col items-center">
						<p className="form-title">
							Your Email is Confirmed! Please Sign in.
						</p>
						<Buttons
							ButtonText="Sign In"
							ButtonType="normal"
							onClick={signIn}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EmailConfirmationPage;
